import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import HeaderOne from '../components/LearningPathsTools'
import HeaderTwo from '../components/HeaderTwo'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import LogoSection from '../components/LogoSection'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Learning Paths - Connect the dots" />
        <LogoSection />
        <div id="main">
          <section id="highlights" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Privacy Policy</h2>
                </header>
                <p>
                  Information we collect and how we use it:
                  We collect two types of information from our website:<br /><br />

                  Individually identifiable information you provide to us.
                  We will not disclose, sell or rent your name or any personal information about you to any third party. You do not need to give personal information to receive information from this site. There are instances online, however, when Learning Paths requests individually identifiable information from you in order to contact you and assist you with further inquiries. We may retain this information for processing and to facilitate requests and to notify you of new services and products (i.e. workshops, new therapists, partner practitioners) that we offer as they become available. Information you submit to us is treated in the same confidential manner as information you might provide in a written format such as an application or donation form.<br /><br />

                  Aggregate information.
                  We use the aggregate information we collect to measure the number of visitors to the different pages and sections of our site and to help us make our site more useful to visitors. The aggregate information we collect is not linked to personal information and we do not report on individual user sessions.  Only unidentifiable aggregate data is shared with outside resources.<br /><br />

                  The tags you add to your highlights or notes are public and are used for autocompletion of tags for others users. So please be careful with anything you enter into the tags tab. Anything you enter here should be considered as data that will be made available to the public. Please refrain from adding passwords and other personal details here.<br /><br /><br />
                </p>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
